















import Vue from 'vue';

import PlayButton from '@/components/PlayButton.vue'

Vue.component('PlayButton', PlayButton);

export default Vue.extend({
  name: 'Main',
  data (): unknown {
    return {
    info: []
    }
  },
})
