
























import Vue from 'vue'
import io from "socket.io-client";

export default Vue.extend({
  name: 'PlayButton',
  data() {
    let date:number = Date.now();

    return {
      dialog: false,
      date,
    }
  },
  computed: {
    socket: {
      get() {
        return this.$store.getters.getGameSock;
      },
      set(value) {
        this.$store.commit('setGameSock', value);
      }
    }
  },
  methods: {
    cancelQueue() {
      this.socket.disconnect();
      this.dialog = false;
    },
    searchPlayer() {
      this.socket = io(`https://${window.location.hostname}:${process.env.VUE_APP_BACKEND_PORT}/pong`, {
        transportOptions: {
          polling: { extraHeaders: { Authorization: 'Bearer ' + localStorage.getItem('token') } },
        },
      });
      this.socket.on('info', () => {
        this.socket.emit('queue');
      });
      this.socket.on('room', (code) => {
        this.dialog = false;
        this.$store.commit('setGameRoom', code);
        this.$router.push('/pregame');
      });
      return;
    },
  },
})
